// extracted by mini-css-extract-plugin
export var articleContent = "index-module--articleContent--NhNfp";
export var articleIntro = "index-module--articleIntro--z4Nv8";
export var articleTitle = "index-module--articleTitle--SOEZx";
export var articlesSection = "index-module--articlesSection--LZ6oe";
export var background = "index-module--background--Ck02K";
export var carouselContainer = "index-module--carouselContainer--SbVZx";
export var contactBackground = "index-module--contactBackground--sYvDa";
export var contactOverlay = "index-module--contactOverlay--7Dp3T";
export var contactSection = "index-module--contactSection--Tx9iR";
export var container = "index-module--container--+MMgw";
export var hello = "index-module--hello--aSsWX";
export var introductionSection = "index-module--introductionSection--V7ZXJ";
export var lifeStory = "index-module--lifeStory--Aobnx";
export var like = "index-module--like--ceL-5";
export var name = "index-module--name--yJdGi";
export var projectIntro = "index-module--projectIntro--tLBgg";
export var projectSection = "index-module--projectSection--UYudA";
export var projectTitle = "index-module--projectTitle--1spt1";
export var seeAll = "index-module--seeAll--eTpZU";
export var subText = "index-module--subText--ppcyX";
export var transitionIn = "index-module--transitionIn--IiU52";