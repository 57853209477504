import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function PreviousArrow(props) {
  const { className, style, onClick } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      className={className}
      style={{ ...style, width: "48", height: "48", zIndex: "999", top: "44%" }}
      onClick={onClick}
    >
      <circle cx="24" cy="24" r="24" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M30.1464 24.0001C30.1464 23.8837 30.1001 23.772 30.0178 23.6897C29.9355 23.6073 29.8238 23.5611 29.7073 23.5611H19.3525L22.1157 20.7987C22.1566 20.7579 22.1889 20.7095 22.211 20.6561C22.2331 20.6028 22.2445 20.5456 22.2445 20.4879C22.2445 20.4302 22.2331 20.373 22.211 20.3197C22.1889 20.2664 22.1566 20.2179 22.1157 20.1771C22.0749 20.1363 22.0265 20.1039 21.9731 20.0818C21.9198 20.0597 21.8626 20.0483 21.8049 20.0483C21.7472 20.0483 21.69 20.0597 21.6367 20.0818C21.5834 20.1039 21.5349 20.1363 21.4941 20.1771L17.9819 23.6893C17.941 23.7301 17.9086 23.7785 17.8864 23.8319C17.8643 23.8852 17.8529 23.9424 17.8529 24.0001C17.8529 24.0579 17.8643 24.115 17.8864 24.1684C17.9086 24.2217 17.941 24.2702 17.9819 24.3109L21.4941 27.8231C21.5349 27.864 21.5834 27.8963 21.6367 27.9184C21.69 27.9405 21.7472 27.9519 21.8049 27.9519C21.8626 27.9519 21.9198 27.9405 21.9731 27.9184C22.0265 27.8963 22.0749 27.864 22.1157 27.8231C22.1566 27.7823 22.1889 27.7339 22.211 27.6805C22.2331 27.6272 22.2445 27.57 22.2445 27.5123C22.2445 27.4546 22.2331 27.3974 22.211 27.3441C22.1889 27.2908 22.1566 27.2423 22.1157 27.2015L19.3525 24.4391H29.7073C29.8238 24.4391 29.9355 24.3929 30.0178 24.3106C30.1001 24.2282 30.1464 24.1165 30.1464 24.0001Z"
        fill="black"
      />
    </svg>
  );
}

export default PreviousArrow;
